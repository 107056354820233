import './App.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { About, Footer, Header, SkillProgress, Skills, Testimonial, Work } from './containers';
import React, { useEffect, useState } from 'react';

import Education from './containers/Education/Education';
import MainProjects from './containers/MainProjects/MainProjects';
import { Navbar } from './components';
import Profile from './components/profile/profile';
import Projects from './containers/Projects/Projects';
import ScrollToTopButton from './containers/TopButton/ScrollTop';
import SplashScreen from './containers/splashScreen/splashScreen';
import { StyleProvider } from './contexts/StyleContext';
import Twitter from './containers/twitter-embed/twitter';
import { splashScreen } from './portfolio';
import { useLocalStorage } from './hooks/useLocalStorage';

const App = () => {
  const darkPref = window.matchMedia('(prefers-color-scheme: dark)');
  const [isDark, setIsDark] = useLocalStorage('isDark', darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] = useState(true);
  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(() => setIsShowingSplashAnimation(false), splashScreen.duration);
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? 'dark-mode' : null}>
      <StyleProvider value={{ isDark: isDark, changeTheme: changeTheme }}>
        {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : (
          <div className={isDark ? 'dark-mode app' : 'app'}>
            <Navbar />
            <Header />
            <About />
            <Work />
            <Education />
            <Skills />
            <SkillProgress />
            <Twitter />
            <Projects />
            <Testimonial />
            <MainProjects />
            <Profile />
            <Footer />
            <ScrollToTopButton />
          </div>
        )}
      </StyleProvider>
    </div>
  );
};

export default App;

import './Progress.scss';

import { AppWrap, MotionWrap } from '../../wrapper';
import { illustration, techStack } from '../../portfolio';

import Build from '../../assets/lottie/build';
import DisplayLottie from '../../components/displayLottie/DisplayLottie';
import { Fade } from 'react-reveal';
import React from 'react';

const StackProgress = () => {
  if (techStack.viewSkillBars) {
    return (
      <Fade bottom duration={1000} distance="20px">
        <div className="skills-container">
          <div className="skills-bar">
            <h1 className=" skills-heading ">Proficiency</h1>

            {techStack.experience.map((exp, i) => {
              const progressStyle = {
                width: exp.progressPercentage,
              };
              return (
                <div key={i} className="skill">
                  <p>{exp.Stack}</p>
                  <div className="meter">
                    <span style={progressStyle}></span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="skills-image">
            {illustration.animated ? (
              <DisplayLottie animationData={Build} />
            ) : (
              <img alt="Skills" src={require('../../assets/images/skill.svg')} />
            )}
          </div>
        </div>
      </Fade>
    );
  }
  return null;
};
// export default AppWrap(StackProgress, "skillProgress");
export default AppWrap(MotionWrap(StackProgress, 'app__skill_progress'), 'stackProgress');

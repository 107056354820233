import './SocialMedia.scss';

import {
  FaEnvelope,
  FaFacebook,
  FaGithub,
  FaGitlab,
  FaInstagram,
  FaKaggle,
  FaLinkedinIn,
  FaMedium,
  FaStackOverflow,
  FaTable,
  FaTwitter,
} from 'react-icons/fa';

import React from 'react';
import { socialMediaLinks } from '../../portfolio';

export default function socialMedia() {
  if (!socialMediaLinks.display) {
    return null;
  }
  return (
    <div className="social-media-div">
      {socialMediaLinks.github ? (
        <a href={socialMediaLinks.github} className="icon-button github">
          <i>
            <FaGithub />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.twitter ? (
        <a href={socialMediaLinks.twitter} className="icon-button twitter" target="_blank" rel="noopener noreferrer">
          <i>
            <FaTwitter />
          </i>
        </a>
      ) : null}
      {socialMediaLinks.linkedin ? (
        <a href={socialMediaLinks.linkedin} className="icon-button linkedin" target="_blank" rel="noopener noreferrer">
          <i>
            <FaLinkedinIn />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.gmail ? (
        <a
          href={`mailto:${socialMediaLinks.gmail}`}
          className="icon-button google"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>
            <FaEnvelope />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.gitlab ? (
        <a href={socialMediaLinks.gitlab} className="icon-button gitlab" target="_blank" rel="noopener noreferrer">
          <i>
            <FaGitlab />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.facebook ? (
        <a href={socialMediaLinks.facebook} className="icon-button facebook" target="_blank" rel="noopener noreferrer">
          <i>
            <FaFacebook />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.instagram ? (
        <a
          href={socialMediaLinks.instagram}
          className="icon-button instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>
            <FaInstagram />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.medium ? (
        <a href={socialMediaLinks.medium} className="icon-button medium" target="_blank" rel="noopener noreferrer">
          <i>
            <FaMedium />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.stackoverflow ? (
        <a
          href={socialMediaLinks.stackoverflow}
          className="icon-button stack-overflow"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>
            <FaStackOverflow />
          </i>
        </a>
      ) : null}

      {socialMediaLinks.kaggle ? (
        <a href={socialMediaLinks.kaggle} className="icon-button kaggle" target="_blank" rel="noopener noreferrer">
          <i>
            <FaKaggle />
          </i>
        </a>
      ) : null}
    </div>
  );
}

import './EducationCard.scss';

import React, { createRef, useContext } from 'react';
import { motion, useRef, useState } from 'framer-motion';

import StyleContext from '../../contexts/StyleContext';

export default function EducationCard({ school }) {
  const imgRef = createRef();

  const GetDescBullets = ({ descBullets }) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li key={i} className="subTitle">
            {item}
          </li>
        ))
      : null;
  };
  const { isDark } = useContext(StyleContext);

  if (!school.logo) console.error(`Image of ${school.name} is missing in education section`);
  return (
    <div>
      <motion.div whileInView={{ x: [-100, 0], opacity: [0, 1] }} transition={{ duration: 0.6 }}>
        <div className="education-card">
          {school.logo && (
            <div className="education-card-left">
              <img
                crossOrigin={'anonymous'}
                ref={imgRef}
                className="education-roundedimg"
                src={school.logo}
                alt={school.schoolName}
              />
            </div>
          )}
          <div className="education-card-right">
            <h5 className="education-text-school">{school.schoolName}</h5>

            <div className="education-text-details">
              <h5 className={isDark ? 'dark-mode education-text-subHeader' : 'education-text-subHeader'}>
                {school.subHeader}
              </h5>
              <p className={`${isDark ? 'dark-mode' : 'education-text-duration'} education-text-duration`}>
                {school.duration}
              </p>
              <p className="education-text-desc">{school.desc}</p>
              <div className="education-text-bullets">
                <ul>
                  <GetDescBullets descBullets={school.descBullets} />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="education-card-border"
        />
      </motion.div>
    </div>
  );
}

import { NavigationDots, SocialMedia, SocialMediaWrapper } from '../components';
import React, { useContext } from 'react';

import StyleContext from '../contexts/StyleContext';

const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    // template string so that we can add classNames dynamically
    //   <div id={idName} className={`app__container ${classNames}`}>
    const { isDark } = useContext(StyleContext);
    return (
      <div className={`app__container ${isDark ? 'dark-mode' : 'app__container '} ${classNames}`}>
        <SocialMediaWrapper />

        <div className="app__wrapper app__flex">
          <Component />
          <div className="copyright">
            <p className="p-text">@2024 Lalit Sharma</p>
            <p className="p-text">All rights reserved</p>
          </div>
        </div>

        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;

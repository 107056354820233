import './Header.scss';

import React, { useContext } from 'react';
import { greeting, illustration } from '../../portfolio';

import { AppWrap } from '../../wrapper';
import DisplayLottie from '../../components/displayLottie/DisplayLottie';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import StyleContext from '../../contexts/StyleContext';
import emoji from 'react-easy-emoji';
import { images } from '../../constants';
import landingPerson from '../../assets/lottie/landingPerson';
import { motion } from 'framer-motion';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const { isDark } = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <div className="app__header app__flex ">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge ">
          <div className="badge-cmp app__flex greeting-text-div">
            <h1 className={isDark ? 'dark-mode greeting-text' : 'greeting-text'}>
              Hi all!
              <span className="wave-emoji">{emoji('👋')}</span>
              {greeting.title}
            </h1>
          </div>

          <div className="tag-cmp  app__flex">
            <p className="p-text " style={{ marginTop: '0.5rem', textTransform: 'capitalize' }}>
              {greeting.subTitle}
            </p>
          </div>

          <div className="tag-cmp  app__flex">
            <p className="p-text " style={{ marginTop: '0.5rem', textTransform: 'capitalize' }}>
              {' '}
              A Crazy Full Stack Developer who wants to explore and learn new things every day. <br />
              <span className="p-emoji">{emoji('💻 + 😃 + 🧠 + 💡 =🏆')}</span>
            </p>
          </div>
        </div>

        <SocialMedia />
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        {/* <img src={images.profile} alt="profile_bg" /> */}
        <div className="greeting-image-div">
          {illustration.animated ? (
            <DisplayLottie animationData={landingPerson} />
          ) : (
            <img alt="man sitting on table" src={require('../../assets/images/manOnTable.svg')}></img>
          )}
        </div>
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>
      <motion.div variant={scaleVariants} whileInView={scaleVariants.whileInView} className="app__header-circles">
        {[images.nextjs, images.react, images.node, images.git, images.ts].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, 'home');
// we are going into the AppWrap and passing in the entire header section as a component and passing in the idName

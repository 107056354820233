import { FaDev, FaGithub, FaLinkedin, FaMedium, FaStackOverflow } from 'react-icons/fa';

import { BsTwitter } from 'react-icons/bs';
import React from 'react';

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a href="https://www.linkedin.com/in/pinglalit/" target="_blank" rel="noreferrer">
          <FaLinkedin />
        </a>
      </div>
      <div>
        <a href="https://twitter.com/lalitdotdev" target="_blank" rel="noreferrer">
          <BsTwitter />
        </a>
      </div>

      <div>
        <a href="https://github.com/lalitdotdev" target="_blank" rel="noreferrer">
          <FaGithub />
        </a>
      </div>
      {/* <div>
        <FaMedium />
      </div>
      <div>
        <FaDev />
      </div> */}
    </div>
  );
};

export default SocialMedia;

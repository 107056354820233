import './Navbar.scss';

import { HiMenuAlt4, HiX } from 'react-icons/hi';
import React, { useContext, useState } from 'react';

import Headroom from 'react-headroom';
import StyleContext from '../../contexts/StyleContext';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { images } from '../../constants';
import { motion } from 'framer-motion';

const Navbar = () => {
  const { isDark } = useContext(StyleContext);
  const [toggle, setToggle] = useState(false);
  return (
    <Headroom>
      <nav className={isDark ? 'dark-menu app__navbar ' : 'app__navbar '}>
        {/* <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div> */}
        <a href="/" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">Lalit Sharma</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <ul className="app__navbar-links ">
          {['home', 'about', 'work', 'skills', 'contact Me'].map((item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          ))}
        </ul>
        <li>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <ToggleSwitch />
          </a>
        </li>
        {/* app__navbar-menu */}
        <div className={isDark ? 'dark-menu app__navbar-menu' : 'app__navbar-menu'}>
          <HiMenuAlt4 onClick={() => setToggle(true)} />
          {toggle && (
            <motion.div whileInView={{ x: [300, 0] }} transition={{ duration: 0.85, ease: 'easeOut' }}>
              <HiX onClick={() => setToggle(false)} />
              <ul className="app__navbar-links ">
                {['home', 'about', 'work', 'skills', 'contact me'].map((item) => (
                  <li key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <ToggleSwitch />
                </a>
              </li>
            </motion.div>
          )}
        </div>
      </nav>
    </Headroom>
  );
};

export default Navbar;
